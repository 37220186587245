'use strict'

const _ = require('lodash')
const workerManagerFactory = require('./workerManager')
const messageProxy = require('./utils/messageProxy')
const constants = require('./utils/constants')

function isStorageEnabled() {
    try {
        window.localStorage.setItem('', '')
        window.localStorage.removeItem('')
        return true
    } catch (exception) {
        return false
    }
}

/**
 * @returns {{init: function, sendMessage: function, registerMessageHandler: function}}
 */
function getAPI() {
    let workerMessageProxy = messageProxy.get()
    const appMsgHandlers = []
    const appMsgModifiers = []
    let alreadyInitiated = false

    let workerManager

    function handleWorkerMessage(options, messageData) {
        if (_.invoke(options, 'shouldUseFallbackReportBI', messageData, appMsgHandlers)) {
            _.invoke(options, 'reportBiWithWixBiSession', messageData)
        }
        appMsgHandlers.forEach(handlerCallback => {
            handlerCallback(messageData)
        })
    }

    function isAppInitiated() {
        return alreadyInitiated
    }

    function getDefaults(options) {
        return _.assign({
            applications: [],
            reportWorkerCreated: _.noop,
            reportWorkerTerminated: _.noop,
            fetchScripts: true
        }, options)
    }
    /**
     *
     * @param {object} props
     *
     */
    function init(props) {
        const options = getDefaults(props)
        const {workerProps, fetchScripts} = options
        workerManager = workerManagerFactory.getWorkerManager(workerProps, isStorageEnabled(), options)
        workerManager.initialize(_.partial(handleWorkerMessage, options), options.reportWorkerCreated, options.reportWorkerTerminated, fetchScripts)
        workerMessageProxy.setMessageHandler((message, ports) => workerManager.handleWidgetsCommand(message, ports))
        alreadyInitiated = true
    }

    function registerMessageHandler(callback) {
        appMsgHandlers.push(callback)
    }

    function registerMessageModifier(callback) {
        appMsgModifiers.push(callback)
    }

    function applyMessageModifications(message) {
        return appMsgModifiers.reduce((modifiedMessage, modifier) => modifier(modifiedMessage), message)
    }

    function sendMessageToApp(message, ports) {
        const messageDefaults = {
            intent: constants.WIX_CODE.MESSAGE_INTENT.WIX_CODE_MESSAGE
        }
        message = _.defaults({}, message, messageDefaults)

        if (message) {
            workerMessageProxy.sendOrHoldMessage(applyMessageModifications(message), ports)
        }
    }

    function getWorkerById(id) {
        return workerManager && workerManager.get(id)
    }

    function destroyAppsContainer() {
        if (workerManager) {
            workerManager.terminateStandbyWorker()
            alreadyInitiated = false
            workerMessageProxy = messageProxy.get()
        }
    }

    function sendOrHoldMessage(message) {
        workerMessageProxy.sendOrHoldMessage(message)
    }

    return {
        init,
        sendMessage: sendMessageToApp,
        sendOrHoldMessage,
        registerMessageHandler,
        registerMessageModifier,
        isAppInitiated,
        getWorkerById,
        destroyAppsContainer
    }
}

module.exports = {
    getAPI
}
