'use strict'

const _ = require('lodash')
const storageFacade = require('./utils/storageFacade')
const messageBuilder = require('./utils/messageBuilder')
const constants = require('./utils/constants')

function isInSSR() {
    return typeof window === 'undefined' || window.isPseudoWindow
}

function getSdkParameters() {
    const clientAfterSuccessfulSSR = typeof window !== 'undefined' && !window.clientSideRender
    const inSSR = isInSSR()
    return {
        viewMode: this._hostProps.viewMode,
        locale: this._hostProps.locale,
        storage: this._storageManager.serialize(),
        userWarmup: this._hostProps.userWarmup,
        renderCycle: clientAfterSuccessfulSSR && !inSSR ? 2 : 1,
        renderingEnv: inSSR ? 'backend' : 'browser'
    }
}

function getScripts(sdkScripts, applications, wixCodeScripts) {
    let scripts = _.clone(sdkScripts)
    if (applications.some(app => app.id === constants.DATA_BINDING)) {
        scripts = scripts.concat(wixCodeScripts)
    }
    return scripts.concat(applications)
}

function isMicrosoft(window) {
    return window.navigator && /Edge|Trident/.test(window.navigator.userAgent)
}

function prefetchScripts(scripts, standbyWorker, fetchScripts) {
    let fetchScriptsCount = 0
    if (!isInSSR()) {
        fetchScripts = fetchScripts && window.fetch && !isMicrosoft(window)
        _.forEach(scripts, script => {
            if (fetchScripts && _.includes(script.url, 'parastorage')) {
                ++fetchScriptsCount
                window
                    .fetch(script.url)
                    .then(response => response.arrayBuffer())
                    .then(ab => {
                        standbyWorker.postMessage(messageBuilder.scriptImportMessage(script.url, ab), [ab])
                    })
            } else {
                const id = `prefetch-${script.id}`
                if (!window.document.getElementById(id)) {
                    const link = window.document.createElement('link')
                    link.setAttribute('rel', 'prefetch')
                    link.setAttribute('href', script.url)
                    link.setAttribute('id', id)
                    window.document.head.appendChild(link)
                }
            }
        })
    }
    return fetchScriptsCount
}

function ensureStandby(fetchScripts) {
    if (!this._standbyWorker) {
        const worker = new window.Worker(this._hostProps.workerUrl)
        worker.addEventListener('message', this.onmessage)
        this._standbyWorker = worker
        sendBootstrapMessage.call(this, !!fetchScripts)
    }
}

function sendBootstrapMessage(fetchScripts) {
    const applications = this._options.applications
    const {viewerSessionId, visitorId, initialTimestamp, metaSiteId, openExperiments, baseVersion, ownerId, santaBase} = this._hostProps

    const sdkSource = {
        id: 'sdk',
        url: this._options.sdkSource
    }
    const namespacesSdkSource = {
        id: 'namespaces-sdk',
        url: this._options.namespacesSdkSource
    }
    const externalComponentsSource = {
        id: 'external-components',
        url: this._options.externalComponentsSource
    }

    const wixCodeNamespacesAndElementorySupportSource = {
        id: 'wixCodeNamespacesAndElementorySupport',
        url: this._options.wixCodeNamespacesAndElementorySupportSource
    }

    const fetchScriptsCount = prefetchScripts(
        getScripts([sdkSource, namespacesSdkSource, externalComponentsSource], applications, wixCodeNamespacesAndElementorySupportSource),
        this._standbyWorker,
        fetchScripts)

    this._standbyWorker.postMessage(messageBuilder.bootstrapMessage({
        sdkParameters: getSdkParameters.call(this),
        debug: window.__WIX_CODE_DEBUG__,
        santaVersion: baseVersion,
        wixCodeBase: `${santaBase}/node_modules/santa-wix-code`,
        sdkSource: sdkSource.url,
        namespacesSdkSource: namespacesSdkSource.url,
        externalComponentsSource: externalComponentsSource.url,
        applications: JSON.stringify(applications),
        wixCodeNamespacesAndElementorySupportSource: wixCodeNamespacesAndElementorySupportSource.url,
        viewerSessionId,
        visitorId,
        ownerId,
        initialTimestamp,
        metaSiteId,
        openExperiments
    }, fetchScriptsCount))
}

function promoteStandby(id) {
    this._workers[id] = this._standbyWorker
    this._reportWorkerCreated(id)
    this._standbyWorker = null
}

function getWixCodeScriptsByRootId(rootIds, widgets) {
    return rootIds.reduce((soFar, rootId) =>
        _.assign(soFar, {
            [rootId]: this._hostProps.getUserCodeUrlsDetails(widgets, rootId)
        }), {})
}

function loadUserCode(wixCodeScriptsByRootId) {
    Object.keys(wixCodeScriptsByRootId).forEach(rootId => {
        const wixCodeScripts = wixCodeScriptsByRootId[rootId]
        prefetchScripts(wixCodeScripts)
        if (!this.has(rootId)) {
            ensureStandby.call(this)
            promoteStandby.call(this, rootId)
        }
        this.send(rootId, messageBuilder.loadUserCodeMessage(rootId, wixCodeScripts))
    })
}

function terminate(id) {
    const worker = this._workers[id]
    if (worker) {
        delete this._workers[id]
        this._terminatedWorkers[id] = worker
        _.defer(() => {this.send(id, messageBuilder.stopMessage(id)); delete this._terminatedWorkers[id]})
        return true
    }
    return false
}

// IMPORTANT
// If you change this API, you should change
// santa-core/santa-wix-code/src/main/IframeWorkerManager.js and
// santa-core/santa-wix-code/src/main/WorkerManager.js
// as well
function handleLoadMessage({applications, rootIds, wixCodeScriptsByRootId, elementoryArguments, routersMap, sdkParameters, rgisByRootId}) {
    rootIds.forEach(rootId => {
        if (!this.has(rootId)) {
            ensureStandby.call(this)
            promoteStandby.call(this, rootId)
        }
        this.send(rootId, messageBuilder.loadMessage(
            rootId,
            elementoryArguments,
            wixCodeScriptsByRootId[rootId],
            applications,
            sdkParameters,
            routersMap,
            this._storageManager.serialize(),
            rgisByRootId[rootId]))
    })
}

function applyChangeAndUpdate({type, key, value}) {
    this._storageManager.setItem(type, key, value)
    const message = messageBuilder.storageWasUpdatedMessage(this._storageManager.serialize())
    _.forEach(this._workers, (dummy, id) => {
        this.send(id, message)
    })
}

function withStorageHandle(handler) {
    return evt => {
        const {data} = evt
        if (_.isMatch(data, {intent: 'BROWSER_API', type: 'setToStorage'})) {
            applyChangeAndUpdate.call(this, data.data)
        }
        handler(evt)
    }
}

function withPmRpcHandle(handler) {
    return evt => {
        const {data, ports} = evt
        if (_.includes(constants.PM_RPC_INTENTS, data.intent)) {
            window.postMessage(data, '*', ports)
        }

        handler(evt)
    }
}

function getScriptEntry(widget) {
    const userScriptDetails = widget.appConfig.userScript

    return {
        id: widget.id,
        url: userScriptDetails.url,
        scriptName: userScriptDetails.scriptName,
        displayName: userScriptDetails.displayName,
        routerData: widget.routerData
    }
}

function getUserCodeUrlsDetails(widgets, rootId) {
    const urls = []

    const pageWidget = _.find(widgets, {id: rootId})

    if (pageWidget) {
        urls.push(getScriptEntry(pageWidget))
    }

    return urls
}

function resolveProps(hostProps) {
    const defaults = {
        getElementoryArguments: () => null,
        getUserCodeUrlsDetails,
        loadUserCode: true,
        viewMode: 'site',
        locale: '',
        userWarmup: null,
        workerUrl: '',
        santaBase: '',
        baseVersion: '',
        ownerId: '',
        viewerSessionId: '',
        visitorId: '',
        initialTimestamp: null,
        metaSiteId: null,
        openExperiments: []
    }
    return _.assign({}, defaults, hostProps)
}

class WorkerManager {
    constructor(hostProps, storageManager, options) {
        this._hostProps = resolveProps(hostProps)
        this._options = options
        this._workers = {}
        this._terminatedWorkers = {}
        this._standbyWorker = null
        this._storageManager = storageManager
    }

    has(id) {
        return Boolean(this._workers[id])
    }

    initialize(onmessage, reportWorkerCreated, reportWorkerTerminated, fetchScripts) {
        this.onmessage = withPmRpcHandle.call(this, withStorageHandle.call(this, ({data}) => onmessage(data)))
        this._reportWorkerCreated = reportWorkerCreated
        this._reportWorkerTerminated = reportWorkerTerminated
        ensureStandby.call(this, fetchScripts)
    }


    send(id, message, ports) {
        const worker = messageBuilder.isStopMessage(message) ? this._terminatedWorkers[id] : this._workers[id]
        if (worker) {
            if (ports) {
                worker.postMessage(message, ports)
            } else {
                worker.postMessage(message)
            }
        }
    }

    get(id) {
        return this._workers[id]
    }

    handleWidgetsCommand(message, ports) { // eslint-disable-line complexity
        switch (message.type) {
            case 'load_user_code':
                if (!this._hostProps.loadUserCode) {
                    break
                }
                loadUserCode.call(this, getWixCodeScriptsByRootId.call(this, message.rootIds, message.widgets))
                break
            case 'load_widgets':
                handleLoadMessage.call(this, {
                    applications: _.reject(message.widgets, _.overSome({type: 'Page'}, {type: 'Popup'}, {type: 'masterPage'})),
                    rootIds: message.rootIds,
                    wixCodeScriptsByRootId: getWixCodeScriptsByRootId.call(this, message.rootIds, message.widgets),
                    elementoryArguments: this._hostProps.getElementoryArguments(message.widgets),
                    routersMap: message.routersMap,
                    sdkParameters: message.sdkParameters,
                    rgisByRootId: message.rgisByRootId
                })
                break
            case 'init_widgets':
                _.forEach(message.contexts, (apps, contextId) => {
                    if (this.has(contextId)) {
                        this.send(contextId, messageBuilder.initMessage(contextId, apps))
                    } else {
                        throw new Error(`Context id ${contextId} is not loaded`)
                    }
                })
                break
            case 'start_widgets':
                if (!message.contexts) {
                    throw new Error('Start message must contain contexts property')
                }
                _.forEach(message.contexts, (context, contextId) => {
                    if (this.has(contextId)) {
                        this.send(contextId, messageBuilder.startMessage(context, contextId))
                    } else {
                        throw new Error(`Context id ${contextId} is not loaded`)
                    }
                })
                break
            case 'trigger_onRender':
                ensureStandby.call(this)
                this.send(message.contextId, message)
                break
            case 'stop_widgets':
                _.forEach(message.widgetIds, workerId => {
                    const terminated = terminate.call(this, workerId)
                    if (terminated) {
                        this._reportWorkerTerminated(workerId)
                    }
                })
                break
            case 'update_wix_code_model_data_after_login':
                _.forEach(message.rootIds, rootId => {
                    this.send(rootId, messageBuilder.updateWixCodeModelDataAfterLoginMessage(
                        rootId,
                        this._hostProps.getElementoryArguments(message.widgets)))
                })
                break
            default:
                if (this.has(message.contextId)) {
                    this.send(message.contextId, message, ports)
                }
                break
        }
    }

    terminateStandbyWorker() {
        if (this._standbyWorker) {
            this._standbyWorker.terminate()
            this._standbyWorker = null
        }
    }
}

module.exports = {
    getWorkerManager(hostProps, isStorageEnabled, options) {
        return new WorkerManager(hostProps, storageFacade.get(isStorageEnabled), options)
    }
}
